import pluginBubbly from './plugin/bubbly-bg';
//import gmap from './gmap';
import '../scss/app.scss';

var $ = require('jquery');

/**
 * option
 * animate: false, // default is true
 * blur: 1, // default is 4
 * bubbleFunc: () => `hsla(${Math.random() * 360}, 100%, 50%, ${Math.random() * 0.25})`, // default is () => `hsla(0, 0%, 100%, ${r() * 0.1})`)
 * bubbles: 100, // default is Math.floor((canvas.width + canvas.height) * 0.02);
 * canvas: document.querySelector("#background"), // default is created and attached
 * colorStart: "#4c004c", // default is blue-ish
 * colorStop: "#1a001a",// default is blue-ish
 * compose: "lighter", // default is "lighter"
*/
bubbly({
  colorStart: '#fff',
  colorStop: '#fff',
  bubbles: 10,
  blur: 1,
  bubbleSizeAdjust: 50,
  compose: 'source-over',
  //bubbleFunc:() => `hsla(178, 98%, 36%, .6)`
  bubbleFunc:() => `hsla(185, 65%, 50%, .7)`
});


const isUA = () =>  {
    const e = navigator.userAgent.toLowerCase()
      , t = {
        iPhone: e.indexOf("iphone") > -1,
        iPad: e.indexOf("ipad") > -1,
        iPod: e.indexOf("ipod") > -1,
        iOS: e.indexOf("iphone") > -1 || e.indexOf("ipad") > -1 || e.indexOf("ipod") > -1,
        Android: e.indexOf("android") > -1 && e.indexOf("mobile") > -1,
        AndroidTablet: e.indexOf("android") > -1 && e.indexOf("mobile") === -1,
        Chrome: e.indexOf("chrome") > -1 && e.indexOf("edge") == -1,
        Firefox: e.indexOf("firefox") > -1,
        Safari: e.indexOf("safari") > -1 && e.indexOf("chrome") == -1,
        IE11: e.indexOf("trident/7") > -1,
        IE: e.indexOf("msie") > -1 && e.indexOf("opera") == -1 || e.indexOf("trident/7") > -1,
        Edge: e.indexOf("edge") > -1
    };
    return function(e) {
        return t[e] || !1
    }
};

const setVisionHeight = () =>  {
	const set_height = window.innerHeight - 66;
	$('._vision-height').css('height', set_height);
	$('canvas').css('height', set_height + '!important');
};

const scrollTop = (el) => {
	let target = document.getElementById(el);
	target.addEventListener('click', function() {
		let currentY = window.pageYOffset; 
		let step = 500/currentY > 1 ? 10 : 100;
		let time_step = 500/currentY * step;
		let interval_id = setInterval(scrollUp, time_step);
		function scrollUp(){
			currentY = window.pageYOffset;
			if(currentY === 0) {
				clearInterval(interval_id);
			} else {
				scrollBy( 0, -step );
			}
		}
	});
}

const goToTopViewCtrl = () => {
	var e = $("#_top")
	, i = $("._top_view_start").offset().top - $(window).height() + $("._top_view_start").outerHeight()
	e.css("display", "none"),
	$(window).scroll(function() {
		$(this).scrollTop() > i && $(this).scrollTop() /*< t */? e.fadeIn(300) : e.fadeOut(300)
	})
};

const spMenuCtrl = () => {
	$('.sp-gnav-list').find('a').on('click', function() {
		$('._dr_gnav_close').trigger('click');
	});
};

const setGmapLink = () => {
	/* 強制的にgmapアプリ
	const
		scheme	= isUA('iOS') ? 'http' : 'comgooglemaps',
		type		= isUA('iOS') ? 'apple' : 'google',
		set_href	= scheme + '://maps.' + type + '.com/maps?q=35.667287,139.71386299999995&z='
	;
	*/
	const set_href	=  'comgooglemaps://maps.google.com/maps?q=35.667287,139.71386299999995&z='
	$('._app-link').attr('href', set_href);
};

const setGnaviDrawer = () => {
    var t = 0
      , e = document.getElementById("_dr_gnav")
      , n = function() {
        $("html").addClass("dr_gnav_end"),
        $("#main").css({
            top: "-" + t + "px"
        })
    };
    $("._dr_gnav_open").on("click", function(o) {
        o.preventDefault();
        var i = $("#_dr_contents");
        t = $(window).scrollTop(),
        $("html").addClass("dr_gnav_on dr_gnav_cover_on"),
        $(".main").css({
            top: "-" + t + "px"
        }),
        $(".dr_gnav_scroll_area").scrollTop(1),
        $(".dr_gnav_scroll_area").on("scroll", function() {
            for (var t = i.find("._view_target"), e = t.length, n = 0, o = 0, a = 0; a <= e; a++)
                n += t.eq(a).innerHeight();
            o = n - $(".dr_gnav_scroll_area").innerHeight(),
            $(this).scrollTop() <= 1 ? $(this).scrollTop(1) : $(this).scrollTop() >= o && $(this).scrollTop(o)
        }),
        e.addEventListener("transitionend", n, !1)
    }),
    $("._dr_gnav_close").on("click", function(o) {
        o.preventDefault(),
        e.removeEventListener("transitionend", n, !1),
        $("html").removeClass("dr_gnav_on dr_gnav_end"),
        $(".dr_gnav_scroll_area").off("scroll"),
        setTimeout(function() {
            $("html").removeClass("dr_gnav_cover_on")
        }, 250),
        $(window).scrollTop(t)
    })
}

const loadingCtrl = () => {
	$('.loading').css('opacity', 0).on('transitionend', function() {
		$(this).remove();
	});
}

$.fn.scrollMove = function(){
	$(this).each(function(){
		var $this = $(this);
		var target = $this.data('scroll-target');

		$this.on("click",function(e){
			e.preventDefault();
			var offset = document.getElementById(target).offsetTop;
			$('html,body').animate({ scrollTop: offset - 66 }, 'swing');
		});
	});
};

window.addEventListener('DOMContentLoaded',function(e){
  $('[data-scroll-target]').scrollMove();
  scrollTop('_top');
  goToTopViewCtrl();
  setVisionHeight();
  setGnaviDrawer();
  setGmapLink();
  spMenuCtrl();
  loadingCtrl();
},false);

